<template>
    <div class="alert-page">
        <v-row v-if="loading || !alertUser.name" class="sub-cont align-center pt-0 pt-md-3">
            <v-col cols="12" md="10" offset-md="1" v-if="loading">
                <v-skeleton-loader
                    v-for="n in 5"
                    :key="n"
                    :loading="true"
                    type="list-item-three-line"
                    class="mx-auto my-0"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="10" offset-md="1" v-if="!loading">
                <v-card min-height="60vh">
                    <v-card-text>
                        <v-alert type="error" outlined prominent class="text-center title font-weight-medium">
                            We could not locate the alert you're looking for. <br> Please confirm you clicked or pasted the correct URL.
                        </v-alert>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else class="sub-cont pt-0 pt-md-3 align-center">
            <v-col cols="12" md="10" offset-md="1" v-if="preview" class="pb-0">
                <v-alert type="info" dense class="mb-0 body-2 lh-11 mb-0 font-weight-medium">This is a preview of your alert page. Dates are for placement only.</v-alert>
            </v-col>
            <v-col cols="12" md="10" offset-md="1">
                <v-card class="Dno-br-bot elevation-2 bg-wavelines-secondary-dark" color="secondary" dark>
                    <v-card-text class="px-2 px-md-8 pb-2 pb-md-6 text-center d-flex align-center justify-center">
                        <div style="width: 100%;">
                            <v-row no-gutters class="no-print">
                                <v-col cols="12" md="3" class="text-center" v-if="alert.status==1 && alertUser.image">
                                    <a :href="alertUser.image" target="_blank" title="View Photo">
                                        <v-avatar class="action-item elevation-4 mt-0 mt-1 mt-md-2" v-if="alertUser.image" size="130">
                                            <v-img class="img-border-primary elevation-3" :src="alertUser.image" />
                                        </v-avatar>
                                    </a>
                                </v-col>
                                <v-col cols="12" :md="(alert.status==1 && alertUser.image) ? 9 : 12">
                                    <div style="max-width: 630px;" class="hidden-sm-and-down mx-auto">
                                        <h1 v-if="alert.status == 2" class="text-h4 white--text text-center mt-3 mt-md-3 mb-3 shadowed mx-auto lh-23">{{ firstName }} has responded to their safety check-in and this alert is no longer active.</h1>
                                        <h1 v-else class="text-h4 white--text text-center mt-3 mt-md-3 mb-3 shadowed mx-auto lh-23">{{ alertUser.name }} has not responded to their safety check-in and may need your help.</h1>
                                        <p v-if="alert.status == 1" class="mb-0 subtitle-1 text-center shadowed font-weight-medium white--text">
                                            <v-btn class="mx-2 mb-3 mb-sm-0" :href="'tel:' + alertUser.phone_short" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-phone</v-icon> Call {{ firstName }} {{ alertUser.phone }}</v-btn>
                                            <v-btn class="mx-2" :href="'mailto:' + alertUser.email" target="_blank" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-envelope</v-icon> Email {{ firstName }}</v-btn>
                                        </p>
                                    </div>
                                    <div class="hidden-md-and-up">
                                        <h1 v-if="alert.status == 2" class="headline white--text text-center mt-3 mt-md-3 mb-6 mx-auto lh-17">{{ firstName }} has responded to their safety check-in and this alert is no longer active.</h1>
                                        <h1 v-else class="title white--text text-center mt-3 mt-md-3 mb-4 mx-3 lh-15">{{ alertUser.name }} has not responded to their safety check-in and may need your help.</h1>
                                        <p v-if="alert.status == 1" class="mb-0 subtitle-1 text-center shadowed font-weight-medium white--text">
                                            <v-btn class="mx-2 mb-2 mb-sm-0" :href="'tel:' + alertUser.phone_short" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-phone</v-icon> Call {{ alertUser.phone }}</v-btn>
                                            <v-btn class="mx-2 mb-2" :href="'mailto:' + alertUser.email" target="_blank" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-envelope</v-icon> Email {{ firstName }}</v-btn>
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="print-only">
                                <v-col cols="12">
                                    <h1 class="headline black--text text-center mt-0 mb-3 mx-auto">Emergency Alert for {{ alertUser.name }}</h1>
                                    <p class="mb-0 subtitle-1 text-center font-weight-medium black--text">
                                        {{ alertUser.phone }} &bull; {{ alertUser.email }}
                                    </p>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
                <v-alert v-if="alert.status==1" class="mt-2 mt-md-3 mb-0 py-2 white--text elevation-1 px-0 text-center no-print" color="error">
                    <v-icon color="white" large class="float-left ml-5 mt-1">fa fa-exclamation-triangle</v-icon>
                    <v-icon color="white" large class="hidden-sm-and-down float-right mt-1 mr-5">fa fa-exclamation-triangle</v-icon>
                    <div class="mx-auto" style="max-width: 300px;">
                        <h3 class="title lh-14 dshadowed-light white--text">
                            Alert Status: Active
                            <span class="body-2 d-block">
                                Issued {{ $moment.utc(alert.created_at).tz(alertUser.timezone).format('MMMM Do [at] h:mma') }} {{ getTimezone() }}
                            </span>
                        </h3>
                    </div>
                </v-alert>
                <v-alert v-if="alert.status==2" class="mt-2 mt-md-3 mb-0 py-2 white--text elevation-1 px-0 text-center no-print" color="success">
                    <!-- <v-icon color="white" large class="float-left ml-5 mt-1">fa fa-exclamation-triangle</v-icon>
                    <v-icon color="white" large class="hidden-sm-and-down float-right mt-1 mr-5">fa fa-exclamation-triangle</v-icon> -->
                    <div class="mx-auto" style="max-width: 300px;">
                        <h3 class="title lh-14 shadowed-light white--text">
                            Alert Status: Resolved
                            <span class="body-2 d-block">
                                Resolved {{ $moment.utc(alert.updated_at).tz(alertUser.timezone).format('MMMM Do [at] h:mma') }}
                            </span>
                        </h3>
                    </div>
                </v-alert>
                <v-row dense class="mt-1 mt-md-2 no-print" v-if="alert.status==1 && !showAuth && !isAuthd">
                    <v-col cols="12" md="12" lg="6">
                        <v-card class="fill-height" flat color="transparent">
                            <!-- <v-card-title class="hidden title pt-3 pb-2"><v-icon color="primary" class="mr-2">fa fa-info-circle</v-icon> What does this mean?</v-card-title> -->
                            <v-card-text class="secondary--text px-0 pb-0 pt-0">
                                <v-card class="elevation-2 fill-height" color="white">
                                    <v-card-text class="secondary--text pt-3">
                                        <p class="subtitle-1 mb-2 font-weight-medium text-center text-md-left">What does this mean?</p>
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-clock-24.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11">
                                                {{ firstName }} uses I&rsquo;mOkay.me to periodically receive check-in text messages to see if they're okay.
                                            </p>
                                        </div>
                                        <v-divider class="my-2" style="opacity:0.5;" />
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-msg-waiting.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11">
                                                {{ firstName }} has yet to respond to our last check-in request we sent on {{ $moment.utc(alertUser.last_checkin).tz(alertUser.timezone).format('MMMM Do [at] h:mma') }} ({{ thisTimezone }}).
                                            </p>
                                        </div>
                                        <v-divider class="my-2" style="opacity:0.5;" />
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-msg-exclam.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11">
                                                We alerted the emergency contacts {{ firstName }} has provided to see if they can reach them.
                                            </p>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="12" lg="6">
                        <v-card class="fill-height" flat color="transparent">
                            <!-- <v-card-title class="hidden title pt-3 pb-2"><v-icon color="primary" class="mr-2">fa fa-info-circle</v-icon> What does this mean?</v-card-title> -->
                            <v-card-text class="secondary--text px-0 pb-0 pt-0">
                                <v-card class="elevation-2 fill-height" color="white">
                                    <v-card-text class="secondary--text pt-3">
                                        <p class="subtitle-1 mb-2 font-weight-medium text-center text-md-left">What should I do?</p>
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-question.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11">
                                                Don't panic. {{ firstName }} may simply be away from the phone or forgot to reply to our check-in message.
                                            </p>
                                        </div>
                                        <v-divider class="my-2" style="opacity:0.5;" />
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-phone.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11">
                                                Try contacting {{ firstName }}{{ (alert.status==1) ? ' at ' : '' }}<a v-if="alert.status==1" class="font-weight-medium" :href="'tel:' + alertUser.phone_short">{{ alertUser.phone }}</a>{{ (alert.status==1) ? ' or ' : '' }} <a v-if="alert.status==1"  class="font-weight-medium" :href="'mailto:' + alertUser.email" target="_blank">{{ alertUser.email }}</a> to see if they're okay.
                                            </p>
                                        </div>
                                        <v-divider class="my-2" style="opacity:0.5;" />
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-msg-info.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11" v-if="alertUser.lng || alertUser.has_notes || alertUser.has_addresses">
                                                If you're unable to reach {{ firstName }}, review the information below they've asked us to share in case of an emergency.
                                            </p>
                                            <p class="body-2 mb-0 lh-11" v-else>
                                                When we hear from {{ firstName }} we will notify their emergency contacts and update this page.
                                            </p>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                
                <v-row dense class="no-print mt-2" v-if="alert.status == 1 && (alertUser.lng || alertUser.has_notes || alertUser.has_addresses)">
                    <v-col cols="12">
                        <v-card class="elevation-2" color="secondary" dark>
                            <v-card-text class="white--text" :class="(showAuth) ? 'pt-2 px-1 pb-2 px-md-3 pb-md-3' : 'pt-2'">
                                
                                <h3 class="title text-center"><v-icon color="primary" class="mr-2">fa fa-life-ring</v-icon> In Case of Emergency:</h3>
                                <v-divider class="mt-2 mb-3" />
                                <p v-show="!showAuth || isAuthd" class="subtitle-1 text-md-h6 font-weight-medium text-md-center white--text shadowed mt-1 px-3 mb-0 lh-14 mx-auto" :style="(!isAuthd) ? 'max-width: 650px;' : ''">
                                    {{ firstName }} has asked us to share the following information with their emergency contacts{{ (isAuthd) ? '.' : ':' }}
                                    <template v-if="isAuthd">
                                        <span class="d-block mt-2 mb-1">Please read carefully as {{ firstName }} may need you to take action or relay this information to others.</span>
                                    </template>
                                </p>
                                
                                <v-list rounded two-line class="pt-1 no-bb mx-auto" style="max-width: 650px;" color="secondary" dark v-if="!showAuth && !isAuthd">
                                    <v-list-item class="px-0 delevation-1" v-if="alertUser.lng" link @click.native="showAuth=true">
                                        <v-list-item-avatar tile size="40" class="text-center">
                                            <v-icon color="primary" class="mt-1" size="35">fa fa-map-marker-alt</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-1 white--text font-weight-medium">
                                                Last-Login Location
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="no-truncate">{{ firstName }} provided their location during a recent use of this app.</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider v-if="alertUser.lng" />
                                    <template v-for="noteType in alertUser.note_types">
                                        <v-list-item class="px-0" :key="'nt-' + noteType" link @click.native="showAuth=true">
                                            <v-list-item-avatar tile size="40">
                                                <v-icon color="primary" class="mt-1" size="35">{{ getIcon(noteType) }}</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title class="subtitle-1 white--text font-weight-medium">
                                                    {{ getType(noteType) }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="no-truncate">{{ getNoteDesc(noteType) }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider :key="'ntd-' + noteType" />
                                    </template>
                                </v-list>

                                <p class="text-center mb-0" v-if="!isAuthd && !showAuth">
                                    <v-btn class="mt-3 mb-1" rounded @click.native="showAuth=true" color="primary">View Emergency Information</v-btn>
                                </p>
                                
                                <Auth v-if="!isAuthd && showAuth" :firstName="firstName" :user="user" :preview="preview" :contact="contact" authType="location" @authd="handleAuth($event)" />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                                
                <v-expansion-panels multiple :model-value="true" class="mt-3 no-print" v-model="panels" v-if="isAuthd" id="alert-notes">
                    <v-expansion-panel expand :key="user.geo" v-if="isAuthd">
                        <v-expansion-panel-header class="pl-3" @click="scrollToNote()">
                            <span class="font-weight-medium secondary--text title"><v-icon color="primary" class="mr-1 alert-note-icon">fa fa-map-marker-alt</v-icon> Last Login Location</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-divider />
                            <v-alert outlined type="info">
                                <p class="body-2 secondary--text mb-2 mb-md-0">
                                    {{ firstName }} provided their location during a recent use of this app.
                                </p>
                                <p class="body-2 secondary--text mb-0">
                                    <b>Note: </b>This location was last updated <b>{{ $moment.utc(alertUser.updated_at).tz(alertUser.timezone).fromNow() }}</b>. 
                                    Additionally, internet geolocation is not always accurate. 
                                </p>
                            </v-alert>
                            
                            <v-row v-if="isAuthd">
                                <v-col cols="12">
                                    <v-card-text class="px-0 py-0" :key="userKey">
                                        <iframe
                                            width="100%"
                                            height="300"
                                            style="border:0"
                                            class="elevation-2"
                                            loading="lazy"
                                            allowfullscreen
                                            :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyBmlVSyO63l-PzJI0EH3GjhavC0fD2wR5g&q='+alertUser.lat+','+alertUser.lng"
                                        >
                                        </iframe>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <template v-for="item in alertUser.notes">
                        <v-expansion-panel expan :key="item.uuid" @click="preview=null">
                            <v-expansion-panel-header class="pl-3" id="notespanel1" @click="scrollToNote()">
                                <span class="font-weight-medium secondary--text title"><v-icon color="primary" class="mr-1 alert-note-icon">{{ getIcon(item.type) }}</v-icon> {{ getType(item.type) }}</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-divider />
                                <Note :note="item" :name="firstName" class="mt-1 mb-0" />
                                <v-card class="elevation-2 mb-0" v-if="item.files.length">
                                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1"><v-icon color="primary" x-small class="mr-0 d-none">fa fa-paperclip</v-icon> This note has {{ item.files.length }} attachment{{ (item.files.length == 1) ? '' : 's' }}:</v-card-title>
                                    <v-divider />
                                    <v-card-text class="py-1">
                                        <v-list class="pt-0 pb-0">
                                            <template v-for="(file, idx) in item.files">
                                                <v-list-item :key="file.uuid" :class="(item.files.length==1) ? 'pl-0 delevation-2' : 'pl-0'" @click="previewFile=idx">
                                                    <v-list-item-avatar size="100" tile class="elevation-3">
                                                        <v-img v-if="file.type == 1" cover class="secondary" max-width="100" max-height="100" :src="file.file" />
                                                        <div v-if="file.type==2" style="width: 100%;" class="grey lighten-3 text-center pt-3 py-md-5">
                                                            <v-icon :size="($vuetify.breakpoint.smAndDown) ? 50 : 80" color="primary" class="mt-1">fa fa-file-pdf</v-icon>
                                                        </div>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="secondary--text body-2 text-center text-md-left">{{ file.caption || file.name }}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action class="hidden-sm-and-down">
                                                        <v-btn outlined color="primary">View File</v-btn>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-card-text>
                                    <MediaViewer v-if="previewFile!==null" @closed="previewFile=null" :preview="preview" :files="item.files" />
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </template>
                </v-expansion-panels>

                <div class="print-only" v-if="isAuthd">
                    <template v-for="item in alertUser.notes">
                        <v-card :key="'print-'+item.uuid" flat class="mt-3 print-break-page">
                            <v-card-title class="py-3">
                                <span class="font-weight-medium secondary--text title"><v-icon color="black" class="mr-1 float-left alert-note-icon">{{ getIcon(item.type) }}</v-icon> {{ getType(item.type) }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-divider />
                                <Note :note="item" :name="firstName" class="mt-1 mb-0" />
                                <v-card class="elevation-2 mb-0" v-if="item.files.length">
                                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1"><v-icon color="primary" x-small class="mr-0 d-none">fa fa-paperclip</v-icon> This note has {{ item.files.length }} attachment{{ (item.files.length == 1) ? '' : 's' }}:</v-card-title>
                                    <v-divider />
                                    <v-card-text class="pt-2 pb-1">
                                        <v-row dense>
                                        <template v-for="(file, idx) in item.files">
                                            <v-col cols="12" :key="file.uuid">
                                                <template v-if="file.type == 1">
                                                    <img :src="file.file" alt="Attached Image" style="max-width: 100%;" class="mx-auto" />
                                                    <p class="body-2 text-center black--text mt-1 mb-1">{{ file.caption || 'No caption provided for this image.' }}</p>
                                                </template>
                                                <template v-else>
                                                    <p class="body-2 lh-14 mb-1 black--text">
                                                        <b>Document: {{ file.name }}</b><br>
                                                        {{ file.file }}<br>
                                                        {{ file.caption }}
                                                    </p>
                                                </template>
                                                <v-divider v-if="idx < item.files.length-1" />
                                            </v-col>
                                        </template>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </template>
                </div>

                <v-row dense class="mt-1 mt-md-2 no-print">
                    <v-col cols="12">
                        <v-card class="elevation-2" color="white">
                            <v-card-text class="px-4 px-md-8 py-2 d-flex align-center justify-center">
                                <div style="width: 100%;">
                                    <h1 class="title secondary--text text-center mt-3 mb-2 mx-auto" style="max-width: 680px;">{{ (alert.status==1) ? 'We hope' : "We're glad" }} {{ firstName }} is okay!</h1>
                                    <p class="subtitle-1 text-center secondary--text lh-14 mb-2 mb-md-0" v-if="alert.status==1">The last check-in from <span class="text-capitalize">{{ firstName }}</span> was received on {{ $moment.utc(alertUser.last_checkin).tz(alertUser.timezone).format('MMMM Do [at] h:mma') }} {{ thisTimezone }}.</p>
                                    <p class="subtitle-1 text-center secondary--text lh-14 mb-3" v-if="alert.status==1">If you're one of their contacts, you will be notified if {{ firstName }} responds to our check-in.</p>
                                    <p class="body-2 text-center secondary--text"><v-icon small color="secondary" class="mr-1">fa fa-eye</v-icon> This alert has been viewed {{ alert.views || 1 }} time{{ (alert.views > 1) ? 's' : '' }}.</p>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <p v-if="preview" class="text-center mt-5">
                    <v-btn @click.native="alert.status=(alert.status==1) ? 2 : 1; $vuetify.goTo('.alert-page', { container: 'body' })" color="primary" rounded>View as {{ (alert.status==1) ? 'Closed' : 'Active' }}</v-btn>
                </p>
                
            </v-col>
        </v-row>
    </div>
</template>
<script>

import { mapState } from 'vuex'
import tzs from '@/data/timezones'

export default {
    name: 'Alert',
    components: {
        Auth: () => import('@/components/alert/Auth'),
        Note: () => import('@/components/alert/Note'),
        MediaViewer: () => import('@/components/common/MediaViewer'),
    },
    computed: {
        ...mapState(['user']),
        firstName(){
            let parts = this.alertUser.name.split(' ');
            return parts[0] || this.alertUser.name;
        }
    },
    data: () => ({
        accessCode: '',
        alert: {},
        contact: {},
        errors: null,
        isAuthd: false,
        loading: true,
        panels: [1,1,1,1,1],
        preview: null,
        previewFile: null,
        showAuth: false,
        showGeo: false,
        thisTimezone: '',
        timezones: tzs.tzs,
        alertUser: {},
        userKey: 0,
    }),
    methods: {
        initData(){
            this.getAlert();
        },
        getAlert(){ 
            let alertId = this.$route.params.id;
            if(alertId == 'preview' && this.user.uuid){
                this.handlePreview();
                return;
            }
            let request = {
                endpoint: '/alerts/' + alertId, 
                method: 'get',
                apiKey: this.alertUser.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.alertUser = res.data.user;
                    this.alert = res.data.alert;
                    this.contact = res.data.contact;
                    this.$appFn.setTitle("Alert for " + this.alertUser.name + " - I'm Okay", true);
                    this.getTimezone();
                    this.getUserGeo();
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.loading = false;
            });
        },
        scrollToNote(){
            setTimeout(() => {
                if(document.querySelector('.v-expansion-panel-header--active')){
                    this.$vuetify.goTo('.v-expansion-panel-header--active', {
                        container: 'body',
                        offset: 10
                    });
                }else{
                    this.$vuetify.goTo('.v-expansion-panels', {
                        container: 'body',
                        offset: 20
                    });
                }
            }, 100);
        },
        handlePreview(){
            this.preview = true;
            this.loading = false;
            this.alertUser = this.user;
            this.alertUser.notes = this.user.notes;
            this.alertUser.contacts = this.user.contacts;
            this.alertUser.note_types = [];
            this.getTimezone();
            this.getUserGeo();
            for(let i in this.user.notes){
                this.alertUser.note_types.push(this.user.notes[i].type);
            }
            this.alert = {
                status: 1,
            };
            this.user.has_notes = (this.user.notes.length) ? true : false;
            this.$appFn.setTitle("Alert for " + this.alertUser.name + " - I'm Okay", true);
        },
        handleAuth(data){
            if(this.preview){
                this.isAuthd = true;
                return;
            }
            this.alertUser.lat = data.lat;
            this.alertUser.lng = data.lng;
            this.alertUser.notes = data.notes;
            this.isAuthd = true;
            ++this.userKey;
        },
        getTimezone(){
            for(let i in this.timezones){
                if(this.timezones[i].value == this.alertUser.timezone){
                    this.thisTimezone = this.timezones[i].abbr; 
                }
            }
        },
        getUserGeo(){
            if(this.alertUser.geo_updated){
                let userLast = this.$moment.utc(this.alertUser.geo_updated).tz(this.alertUser.timezone);
                let nowTime = this.$moment.utc().tz(this.alertUser.timezone);
                if(userLast.add(20, 'days').isAfter(nowTime) && this.alertUser.show_geo){
                    this.showGeo = true;
                }
            }
        },
        getIcon(itemType){
            if(itemType == 1){
                return 'fa fa-notes-medical';
            }else if(itemType == 3){
                return 'fa fa-dog';
            }else if(itemType == 2){
                return 'fa fa-users';
            }else if(itemType == 4){
                return 'fa fa-home';
            }

            return 'fa fa-comment-medical';
        },
        getType(itemType){
            if(itemType == 1){
                return 'Medical Notes';
            }else if(itemType == 3){
                return 'Pet Care Notes';
            }else if(itemType == 2){
                return 'Family Care Notes';
            }else if(itemType == 4){
                return 'Property Notes';
            }else if(itemType == 5){
                return 'Emergency Notes left by ' + this.user.name;
            }
        },
        getNoteDesc(idx){
            if(idx == 1){
                return 'May include medication allergies or medical conditions ' + this.firstName + ' may have.';
            }else if(idx == 2){
                return 'Actions that may be needed for those left in their care.';
            }else if(idx == 3){
                return 'Instructions for feeding and caring for their pets.';
            }else if(idx == 4){
                return 'Notes for accessing and caring for their home and plants.';
            }

            return 'Notes that ' + this.firstName + ' asked us to share with you.';
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
        // isAuthd() {
        //     if (this.isAuthd == true) {
        //         // setTimeout(() => document.getElementById('notespanel1').click(), 200);
        //     }
        // }
    }
}
</script>